import { dayjsExtended } from "./utils";

export const ENDPOINT_LOCAL = "http://andre-mac.local:3001";
export const ENDPOINT = "https://by8f7az6rh.execute-api.us-west-1.amazonaws.com/Prod/";
export const SITE_BANNER = "Lamping Giving Tree 2024";
const SCHOOL_ADDRESS = "2551 Summit Grove Dr. Henderson, NV 89052";
export const DEADLINE = "2024-12-4 18:00";
export const TIMEZONE = "America/Los_Angeles";
export const donationDeadline = dayjsExtended.tz(DEADLINE, TIMEZONE).format("MMM Do hA");
export const SUPPORT_EMAIL = "lamping@giving-trees.com";
export const INSTRUCTIONS = `Thank you for adopting a student this year for our Giving Tree.

First choose an ornament. Some ornaments are clothing and some ornaments are toys or requested gifts. Please fill out required information and when you submit you will receive a confirmation email with a donation id#. <mark>If you choose to buy clothing please purchase a full outfit. Donate one item or as many as makes you Merry.</mark>

Then return the gifts to the Lamping campus located at ${SCHOOL_ADDRESS} as soon as possible or by the deadline ${donationDeadline}. Write on a gift tag the donation id# and attach to your gifts. <mark>This donation id# will be found on the email you will receive after you choose an ornament. Please do not wrap your gifts.</mark>
`;

export const SEPARATE_TOYS_APPAREL = true;
export const SCHOOL_OPTIONS = ["Lamping", "Galloway", "Wiener Elementary"];
export const TEACHER_GUARDIAN_INFO = true;
