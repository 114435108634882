import { ENDPOINT, ENDPOINT_LOCAL } from "./config";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  AdminViewSummaryChildItem,
  AdminViewSummaryDonationItem,
  ChildItem,
  SummaryTree,
  WishType,
} from "@shared/types";

export const FUNCTIONS_BASE_URL =
  process.env.NODE_ENV === "development" ? ENDPOINT_LOCAL : ENDPOINT;

export const getWishItemKey = (wishType: WishType): "toyWishes" | "apparelWishes" =>
  wishType === "ALL" || wishType === "TOY" ? "toyWishes" : "apparelWishes";

type RequestError = { status: number };

function getTreeData(): Promise<SummaryTree> {
  return fetchWrapper(`${FUNCTIONS_BASE_URL}/tree`);
}

export function useGetTreeData(queryOptions = {}) {
  return useQuery<SummaryTree, RequestError, { responseData: SummaryTree; allDonated: boolean }>({
    queryKey: ["tree", "list"],
    queryFn: () => getTreeData(),
    select: data => {
      const flattenOrnaments = [];
      if (data.ids.length) {
        data.ids.forEach(id => flattenOrnaments.push(...data.details[id]));
      }
      const allDonated =
        flattenOrnaments.length && flattenOrnaments.filter(child => !child.donated).length === 0;
      return { responseData: data, allDonated };
    },
    ...queryOptions,
  });
}

async function getAdminSummary(secret: string) {
  return fetchWrapper(`${FUNCTIONS_BASE_URL}/admin`, {
    headers: {
      Authorization: `Bearer ${secret}`,
    },
  });
}

export function useGetAdminSummary(secret: string, queryOptions) {
  return useQuery<
    {
      children: AdminViewSummaryChildItem[];
      donations: AdminViewSummaryDonationItem[];
    },
    RequestError
  >({ queryKey: ["admin", "list"], queryFn: () => getAdminSummary(secret), ...queryOptions });
}

async function getChildInfo(id: string, itemType: WishType) {
  return fetchWrapper(`${FUNCTIONS_BASE_URL}/child/info/${id}/${itemType}`);
}

export type ChildInfoResponse = Partial<Pick<ChildItem, "toyWishes" | "apparelWishes">> &
  Pick<ChildItem, "age" | "gender">;

export function useGetChildInfo(id: string, itemType: WishType, queryOptions = {}) {
  return useQuery<ChildInfoResponse>({
    queryKey: ["tree", "details", id, itemType],
    queryFn: () => getChildInfo(id, itemType),
    ...queryOptions,
  });
}

export async function getChildDetails(id: string, secret: string) {
  return fetchWrapper(`${FUNCTIONS_BASE_URL}/child/details/${id}`, {
    headers: {
      Authorization: `Bearer ${secret}`,
    },
  });
}

export function useChildDetails(id: string, secret: string) {
  return useQuery<ChildItem, RequestError>({
    queryKey: ["children", "detail", id],
    queryFn: () => getChildDetails(id, secret),
  });
}

export async function sendSubmission(data) {
  const { childId } = data;
  const response = await fetch(`${FUNCTIONS_BASE_URL}/donate/${childId}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw response;
  }
  return response;
}

export async function postNewChild(data, secret) {
  return await fetch(`${FUNCTIONS_BASE_URL}/child`, {
    method: "POST",
    mode: "cors",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${secret}`,
    },
    body: JSON.stringify(data),
  });
}

export async function postWaitlist(data) {
  return await fetch(`${FUNCTIONS_BASE_URL}/waitlist`, {
    method: "POST",
    mode: "cors",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

export async function updateChild(data, secret) {
  return await fetch(`${FUNCTIONS_BASE_URL}/child/${data.id}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${secret}`,
    },
    body: JSON.stringify(data),
  });
}

export async function deleteChild(id, secret) {
  return await fetch(`${FUNCTIONS_BASE_URL}/child/${id}`, {
    method: "DELETE",
    mode: "cors",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${secret}`,
    },
  });
}

async function updateDonation(data, secret) {
  return await fetchWrapper(`${FUNCTIONS_BASE_URL}/donation/${data.id}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${secret}`,
    },
    body: JSON.stringify(data),
  });
}

export function useUpdateDonation() {
  return useMutation<
    Partial<AdminViewSummaryDonationItem>,
    RequestError,
    {
      donation: Partial<Omit<AdminViewSummaryDonationItem, "received">> & { received?: boolean };
      secret: string;
    }
  >({
    mutationFn: ({ donation, secret }) => updateDonation(donation, secret),
  });
}

async function deleteDonation(donationId, childId, itemType, secret) {
  return await fetch(`${FUNCTIONS_BASE_URL}/donation/${donationId}`, {
    method: "DELETE",
    mode: "cors",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${secret}`,
    },
    body: JSON.stringify({ childId, itemType }),
  });
}

export function useDeleteDonation() {
  return useMutation<
    unknown,
    RequestError,
    { donationId: string; childId: string; itemType: WishType; secret: string }
  >({
    mutationFn: ({ donationId, childId, itemType, secret }) =>
      deleteDonation(donationId, childId, itemType, secret),
  });
}

async function fetchWrapper(url: string, config?: RequestInit) {
  const response = await fetch(url, config ?? {});
  if (response.ok) {
    return await response.json();
  }
  return Promise.reject(response);
}

export function doNotRetryOn4xx(failureCount, error) {
  if (error.status >= 400 && error.status < 500) {
    return false;
  }
  if (failureCount >= 3) {
    return false;
  }
}
